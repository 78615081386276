.catalog-main {
    .catalog-content {
        display: flex;
        flex-wrap: wrap;
        .text-no-products{
            font-size: 50px;
            color: plum;
        }
    }
    .sorting-and-filters {
        .sorting-filter-items {
            display: flex;
            flex-wrap: wrap;

            .sorting-filter-item {
                width: 23%;
                height: 50px;
                border-bottom: 2px solid black;
                margin: 10px 9px;

                .select-component {
                    width: 50%;
                    margin: 10px 5px;
                    border: none;
                    -webkit-appearance: none;
                    appearance: none;
                }
            }
            .checkbox-filters{
                display: flex;
                justify-content: space-between;
                padding-top: 5px;
            }
        }

    }
    .catalog-pagination{
        display: flex;
        justify-content: space-around;
    }
}