.add-cart-remove{
    width: fit-content;
    background: lightblue;
    display: flex;
    align-items: center;
    border-radius: 18px;

    .btn-count {
      background: #2196f3;
      color: white;
      height: 36px;
      width: 36px;
      padding: 5px 10px;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      // margin: 0 5px;

      &:hover {
        background: #1976d2;
      }
    }

    .span-quantity {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      margin: 1px 10px;
      // background: lightblue;
    }
}