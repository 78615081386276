.order-item {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #ccc;
    justify-content: space-between;
  
    .image {
      flex: 0 0 80px;
      margin-right: 16px;
  
      img {
        width: 100%;
        max-width: 100%;
        height: auto;
        border-radius: 4px;
      }
    }
  
      .name {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 8px;
      }
  
      .count-price {
        display: flex;
        align-items: center;
  
        span {
          font-size: 16px;
          color: #333;
        }
      }
    }
  
  