.product-main {
    display: flex;
    justify-content: space-around;
    padding-top: 5px;
    margin-top: 5px;

    .product-view {
        width: 50%;

        .product-tags {
            display: flex;
            margin: 5px 0;

            .product-tag {
                background: lightblue;
                border-radius: 5px;
                margin: 1px 10px;
                color: aliceblue;
                padding: 1px 20px;
            }
        }

        .product-image {
            // width: 50%;

            .product-img {
                width: 100%;
                height: 468px;
                object-fit: cover;
                border-radius: 5px;
            }

            .product-slider {
                .product-slider-image {
                    width: 24%;
                    border: 2px solid lightgray;
                    margin: 2px 2px;
                    height: 120px;
                    object-fit: cover;
                }
            }
        }
    }

    .product-information {
        // large monitor???
        width: 45%;
        margin: 50px 2px 0 2px;

        .product-title {
            p {
                font-size: 24px;
            }
        }

        .product-buy {
            .btn-icon {
                color: grey;
                margin: 0 3px;
            }
            .favourite{
                color: gold;
            }
            .not-favourite{
                color: grey;
            }
        }

        .product-short-description {
            margin-top: 10px;
        }

        .product-description {
            #productDescriptionText {
                height: 26px;
                overflow: hidden;
            }
        }

        .product-properties {
            margin-top: 10px;
            background: lightgray;
            border-radius: 5px;
            padding-top: 10px;

            .product-properties-content {
                display: flex;
                justify-content: space-around;

                .property-title {
                    font-size: large;
                    font-weight: 600;
                }

                .property-item {
                    font-size: 24px;
                }
            }
        }
    }
}