.new-order-main {
    margin: 0 auto;
    display: flex;
    justify-content: space-around;

    .order-form {
        width: 50%;

        h2 {
            font-size: 24px;
            margin-bottom: 20px;
        }

        label {
            display: block;
            margin-bottom: 8px;
        }

        input,
        textarea,
        select {
            width: 100%;
            padding: 8px;
            margin-bottom: 16px;
            border: 1px solid #ccc;
            border-radius: 4px;
        }

        select {
            appearance: none;
        }

        button {
            padding: 10px 16px;
            background-color: #007bff;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 16px;
            transition: background-color 0.2s;

            &:hover {
                background-color: #0056b3;
            }
        }

    }

    .order-items {
        .order-subtotals {
            .order-subtotal {
                display: flex;
                justify-content: space-between;
            }
        }

        .order-total {
            display: flex;
            justify-content: space-between;
        }
    }
}