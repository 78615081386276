.favorite-page-item{
    width: 50%;
    display: flex;
    justify-content: space-between;
    background: lightgrey;
    margin: 2px;
    border: 2px double goldenrod;
    padding: 4px;
    .favorite-item-image{
        img{
            // width: 30%;
            // max-width: 30%;
            height: 170px;
            object-fit: contain;
        }
    }
    .favorite-item-details{
        display: flex;
        justify-content: space-around;
    }
}