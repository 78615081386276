.order-modal-item{
    margin: 5px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
        height: 100px;
    }
    .order-modal-item-total{
        font-weight: 700;
        color: saddlebrown;
    }
}