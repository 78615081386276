.cart-main {
    display: flex;

    .cart-items {
        width: 70%;
    }

    .cart-information {
        width: 30%;
        background-color: #f2f2f2;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        text-align: center;

        h3 {
            font-size: 24px;
            margin-bottom: 10px;
        }

        .cart-count {
            font-size: 16px;
            color: #555;
            margin-bottom: 15px;

            span {
                font-weight: bold;
                margin-left: 5px;
            }
        }

        .btn-promo-code {
            color: red;
        }

        .bnt-order {
            width: 100%;
            background-color: #2196f3;
            color: white;
            padding: 10px 20px;
            border: none;
            cursor: pointer;
            margin: 0;
            font-size: 16px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            &:hover {
                background-color: #1976d2;
            }
        }

        .cart-total {
            font-size: 20px;
            font-weight: bold;
            color: #555;
            margin-top: 15px;
        }
    }
}

.modal-body {

    .order-modal-header {
        // dzel
        :first-child {
            margin-left: 40px;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .order-grandtotal{
        font-size: large;
        font-weight: 800;
        color: blue;
        text-align: end;
    }
}