.slider {
    display: flex;
    align-items: center;
  
    .slide-container {
      display: flex;
      overflow: hidden;
      width: 100%;
    }
  
    .slide {
      flex: 0 0 25%;
      border-radius: 5px;
      margin: 1px 5px;
      div{
        img{
          border-radius: 5px;
        }
      }
    }
  
    .btn-arrow {
      background: transparent;
      border: none;
      cursor: pointer;
      font-size: 24px;
      margin: 0 10px;
    }
  }
  