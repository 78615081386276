.div-delivery{
    display: flex;
    background: skyblue;
    justify-content: space-around;
    padding: 20px 0;
    margin: 2px 5px;
    div{
        width: 25%;
        text-align: center;
        svg{
            font-size: 70px;
            color: blue;
        }
        p{
            font-size: 24px;
        }
    }
}