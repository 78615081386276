#btnCart {
    position: relative;

    .span-cart-product-count {
        position: absolute;
        padding: 0;
        color: white;
        font-weight: 700;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        background-color: blue;
        border-radius: 50%;
    }
}